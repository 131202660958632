import { configureStore } from '@reduxjs/toolkit';
import MessagesReducer from './_reducers/MessagesReducer'
import AccountReducer from './_reducers/AccountReducer'
import LayoutReducer from './_reducers/LayoutReducer'
export const store = configureStore({
    reducer: {
        messages: MessagesReducer,
        account: AccountReducer,
        layout: LayoutReducer
    },
});