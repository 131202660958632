import { createSlice } from '@reduxjs/toolkit';

export const messagesReducer = createSlice({
    name: 'messages',
    initialState: {
        messages: [],
        pageList: 1
    },
    reducers: {
        setMessages: (state, action) => {
            state.messages = action.payload;
        },
        setPage: (state, action) => {
            state.pageList = action.payload;
        },
    },
});

export const { setMessages, setPage } = messagesReducer.actions;

export default messagesReducer.reducer;