import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import NotificationsList from "./../views/notifications/NotificationList";
import NotificationDetails from "./../views/notifications/NotificationDetail";
import PageHeader from "./PageHeader/PageHeader";

const TheContent = () => {
    return (
        <div className={`page-header header-filter `}>
            <BrowserRouter>
                <Switch>
                    <Route path="/landing" render={(props) => <PageHeader {...props} />} />
                    <Route path="/notifications" render={(props) => <NotificationsList {...props} />} />
                    <Route path="/notification/:id" render={(props) => <NotificationDetails {...props} />} />
                </Switch>
            </BrowserRouter>

        </div >
    )


}

export default TheContent;