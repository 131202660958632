
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import LandingsPage from "../../views/landing/Landings";
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  alignSquares: {
    transform: 'translate(-50%, 9%) !important;'
  },
});
export default function PageHeader() {
  const classes = useStyles();
  return (
    <div >
      <Container>
        <div className={`content-center ${classes.alignSquares}`}>
          <LandingsPage />
        </div>
      </Container>
    </div>
  );
}
