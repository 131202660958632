
import React, { useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { useHistory } from "react-router";
import PersonIcon from '@mui/icons-material/Person';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { accountService } from '../../_services'
import { useDispatch, useSelector } from "react-redux";
import { setUser } from '../../_reducers/AccountReducer'

export default function IndexNavbar() {
  let history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector(state => state['account'].token);
  const user = useSelector(state => state['account'].user);

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [color, setColor] = React.useState("navbar-green");

  React.useEffect(async () => {
    var account = JSON.parse(await getAccount());
    if (account != null)
      dispatch(setUser(account.user));

    window.addEventListener("scroll", changeColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };

  }, [token]);

  const getAccount = async () => {
    return await accountService.GetAccount(token).then(res => {
      return res;
    }).catch(err => {
      return null;
    });
  }

  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-green");
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };
  const scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <span>3StockADay </span>
          </NavbarBrand>

          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  3StockADay
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            {user != null ? (
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  title="Register"
                  rel="noopener noreferrer"
                  href="#"
                >
                  <PersonIcon /> <span className="ml-2">Hello!, {user?.name}</span>
                </NavLink>
              </NavItem>
            ) :
              (
                <NavItem className="p-0">
                  <NavLink
                    data-placement="bottom"
                    title="Register"
                    rel="noopener noreferrer"
                    href="#"
                    onClick={() => history.push("/register")}
                  >
                    <PersonIcon /> <span className="ml-2">Register</span>
                  </NavLink>
                </NavItem>
              )}


          </Nav>
        </Collapse>
      </Container >
    </Navbar >
  );
}
