import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { URL_SERVER } from "../../_helpers/setting";
import { landingService } from '../../_services'

const LandingsPage = () => {
    const token = useSelector(state => state['account'].token)
    const [landings, setLandings] = React.useState(fetch());
    const { addToast } = useToasts();
    const [online, setOnline] = useState(false);

    React.useEffect(async () => {
        setOnline(await isAvailableServer());
    }, [])

    const isAvailableServer = async () => {
        return true;
        const timeout = new Promise((resolve, reject) => {
            setTimeout(reject, 5000, 'Request timed out');
        });
        const request = fetch(`${URL_SERVER}/api/v1/Landing`);
        return await Promise
            .race([timeout, request])
            .then(response => {
                if (response.length == 0) {
                    return false;
                }
                else {
                    return true;
                }
            })
            .catch(error => {
                return false;
            });
    }

    function fetch() {
        return landingService.getLandings(token).then(res => {
            return res;
        }).catch(error => {
            return [];
        })
    }

    return (
        <div className=" section-examples" data-background-color="black">
            <div className="space-50" />
            <Container className="text-center">
                <Row>
                    <Col sm="6">
                        <Link to={online == true ? "notifications" : "#"}>
                            <img
                                alt="..."
                                className="img-raised"
                                src={require("../../assets/img/landing-page.png").default}
                            />
                        </Link>

                    </Col>
                    <Col sm="6">
                        <Link to={online == true ? "home" : "#"}>
                            <img
                                alt="..."
                                className="img-raised"
                                src={require("../../assets/img/landing-page2.png").default}
                            />
                        </Link>

                    </Col>
                </Row>
            </Container>
        </div>

    );
}


export default LandingsPage;