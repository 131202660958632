import { useSelector } from "react-redux";
import { handleResponse } from "../_helpers/handle-response";

const { URL_SERVER } = require("../_helpers/setting");

export const messagesService = {
    getMessages,
    getMessagesById
}

async function getMessages(token, page) {
    const requestOptions = {
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            'device-id': token
        }
    };
    return fetch(`${URL_SERVER}/api/v1/Messages/GetMessages?page=${page}`, requestOptions).then(handleResponse).then(notifications => {
        return JSON.parse(notifications);
    });
}

async function getMessagesById(id) {
    const requestOptions = {
        method: 'GET', headers: {
            'Content-Type': 'application/json',
        }
    };
    return fetch(`${URL_SERVER}/api/v1/Messages/GetById/${id}`, requestOptions).then(handleResponse).then(notification => {
        return JSON.parse(notification);
    });
}