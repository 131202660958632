import React, { useState } from "react";
import classnames from "classnames";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import { useToasts } from "react-toast-notifications";
import { accountService } from '../../_services'
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from '../../_reducers/AccountReducer';
import { Stack } from "@mui/material";
import Button from '@mui/material/Button';

export default function RegisterPage() {
  let history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector(state => state['account'].token)
  const { addToast } = useToasts()
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");
  const [nameFocus, setNameFocus] = React.useState(false);
  const [lastNameFocus, setLastNameFocus] = React.useState(false);
  const [userNameFocus, setUserNameFocus] = React.useState(false);

  const [form, setForm] = useState({
    "userName": "",
    "name": "",
    "lastName": ""
  });

  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, []);

  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
      posX * 0.02 +
      "deg) rotateX(" +
      posY * -0.02 +
      "deg)"
    );
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  }

  const submit = async () => {
    if (!form.userName) {
      addToast("Phone Number is required", {
        appearance: 'info',
        autoDismiss: true,
      })
      return false;
    }
    if (!form.name) {
      addToast("Name is required", {
        appearance: 'info',
        autoDismiss: true,
      })
      return false;
    }
    if (!form.lastName) {
      addToast("Last Name is required", {
        appearance: 'info',
        autoDismiss: true,
      })
      return false;
    }

    var deviceId = token;
    console.log("Register token: " + token)

    if (deviceId === '' || deviceId === null) {
      console.log("Device is empty");
      addToast("Registration not possible", {
        appearance: 'info',
        autoDismiss: true,
      })
      return false;
    }

    if (token === '' || token === null) {
      console.log("FcmToken is empty");
      addToast("Registration not possible", {
        appearance: 'info',
        autoDismiss: true,
      })
      return false;
    }

    var account = await accountService.Register(form.userName, deviceId, token, form.name, form.lastName).catch(error => {
      addToast(error, {
        appearance: 'info',
        autoDismiss: true,
      })
      return null;
    });

    if (account != null) {
      var dataParse = JSON.parse(account);
      dispatch(setUser(dataParse.user))
      history.push('/notifications')
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col className="offset-lg-3 offset-md-3 mt-5" lg="5" md="6">
            <Card className="card-register">
              <CardHeader>
                <CardTitle >Register</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form">
                  <InputGroup
                    className={classnames({
                      "input-group-focus": userNameFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>

                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone Number"
                      type="text"
                      onFocus={(e) => setUserNameFocus(true)}
                      onBlur={(e) => setUserNameFocus(false)}
                      name="userName"
                      value={form.userName}
                      onChange={handleChange}
                    />
                  </InputGroup>

                  <InputGroup
                    className={classnames({
                      "input-group-focus": nameFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Name"
                      type="text"
                      onFocus={(e) => setNameFocus(true)}
                      onBlur={(e) => setNameFocus(false)}
                      name="name"
                      value={form.name}
                      onChange={handleChange}
                    />
                  </InputGroup>

                  <InputGroup
                    className={classnames({
                      "input-group-focus": lastNameFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      onFocus={(e) => setLastNameFocus(true)}
                      onBlur={(e) => setLastNameFocus(false)}
                      name="lastName"
                      value={form.lastName}
                      onChange={handleChange}
                    />
                  </InputGroup>

                </Form>
              </CardBody>
              <CardFooter>
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" color="success" onClick={() => history.push("/landing")}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="success" onClick={submit}>
                    Register
                  </Button>

                </Stack>

              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
