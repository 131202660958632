import { useSelector } from "react-redux";
import { handleResponse } from "../_helpers/handle-response";

const { URL_SERVER } = require("../_helpers/setting");

export const landingService = {
    getLandings
}

async function getLandings(token) {
    const requestOptions = {
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            'device-id': token,
            'fcmtoken': token
        }
    };
    return fetch(`${URL_SERVER}/api/v1/Landing`, requestOptions).then(handleResponse).then(landings => {
        return JSON.parse(landings);
    });
}