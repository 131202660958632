import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../_helpers/handle-response";

const { URL_SERVER } = require("../_helpers/setting");

export const accountService = {
    Register,
    GetAccount,
}

function Register(userName, deviceId, fcmToken, name, lastName) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userName: userName,
            deviceId: deviceId,
            fcmToken: fcmToken,
            name: name,
            lastName: lastName
        })
    };
    return fetch(`${URL_SERVER}/api/v1/Account/Register`, requestOptions).then(handleResponse).then(user => {
        return user;
    });
}

function GetAccount(deviceId) {
    const requestOptions = {
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            'device-id': deviceId
        }
    };
    return fetch(`${URL_SERVER}/api/v1/Account/GetAccount`, requestOptions).then(handleResponse);
}

