
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const getToken = async () => {
    return await createDevice();
}

async function createDevice() {
    const fpPromise = FingerprintJS.load();
    return await (async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise
        const result = await fp.get()

        // This is the visitor identifier:
        const visitorId = result.visitorId
        console.log("Token is:" + visitorId)
        return visitorId;
    })();
    /*var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    length = 50;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;*/
}