import React, { useState } from "react";
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import TheContent from "../components/TheContent";


export default function Index() {

  return (
    <div >
      <IndexNavbar />
      <div className="wrapper">
        <TheContent />
      </div>
    </div>
  );
}
