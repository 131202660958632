import React from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/css/nucleo-icons.css";
import "./assets/scss/blk-design-system-react.scss?v=1.1.0";
import "./assets/demo/demo.css";

import Index from "./views/Index.js";
import RegisterPage from "./views/register/RegisterPage";
import { ToastProvider } from "react-toast-notifications";
import { getToken } from './components/Token';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from './_reducers/AccountReducer';
import BlockUi from 'react-block-ui';
import { Loader, Types } from 'react-loaders';
import 'loaders.css/loaders.min.css';

function App() {
  const blockUI = useSelector(state => state['layout'].blockUI);
  const dispatch = useDispatch();

  React.useEffect(async () => {
    var token = await getToken();
    dispatch(setToken(token));

  }, []);

  return (
    <ToastProvider>
      <BlockUi loader={<Loader active type={"line-scale-pulse-out-rapid"} className="d-flex justify-content-center" color="rgb(98 0 255)" />} blocking={blockUI}>
        <BrowserRouter>
          <Switch>
            <Route path="/landing" render={(props) => <Index {...props} />} />
            <Route path="/register" render={(props) => <RegisterPage {...props} />} />
            <Redirect from="/" to="/landing" />
          </Switch>
        </BrowserRouter>
      </BlockUi>
    </ToastProvider>
  );
}

export default App;
