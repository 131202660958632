
import React, { useState } from "react";
import '../../App.css';
import { messagesService } from '../../_services'
import { UncontrolledAlert, Container } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import Button from '@mui/material/Button';
import { useHistory } from "react-router";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useDispatch, useSelector } from "react-redux";
import { setMessages, setPage } from '../../_reducers/MessagesReducer'
import TextTruncate from 'react-text-truncate';
import { useStyles } from "../../_styles/styles";
import { formatDistance } from 'date-fns';
import { Grid, Stack, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { Loader } from 'react-loaders';
import 'loaders.css/loaders.min.css';

export default function Notifications() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const notifications = useSelector(state => state['messages'].messages)
    const page = useSelector(state => state['messages'].pageList)
    const token = useSelector(state => state['account'].token)
    const [fetchin, setFetching] = useState(false);

    let history = useHistory();
    const { addToast } = useToasts();
    React.useEffect(async () => {
        //dispatch(setBlockUI(true));
        const data = await fetchData();
        //dispatch(setBlockUI(false));
        dispatch(setMessages(data));
    }, [page])

    const fetchData = async () => {
        setFetching(true);
        var data = await messagesService.getMessages(token, page).catch(e => {
            addToast(e, {
                appearance: 'error',
                autoDismiss: true,
            });
            return [];
        });
        setFetching(false);
        return data;
    }
    const nextPage = async () => {
        var newPage = parseInt(page) + 1
        dispatch(setPage(newPage));
    }

    const previousPage = async () => {
        var newPage = parseInt(page) - 1;
        dispatch(setPage(newPage));
    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12}>
                <div className="section section-notifications mt-5" id="notifications">
                    <Container className={classes.marginContent}>
                        <div className="d-flex flex-row">
                            <div className="p-2"><h3 className="text-black">Notifications</h3></div>
                            <div className="p-2"> <Button variant="contained" color="success" onClick={() => history.push("/landing")}>Go to Home</Button></div>
                        </div>
                        <div className="pb-5">
                            {fetchin == true ? <Loader active type={"ball-triangle-path"} className="d-flex justify-content-center mt-5" color="#2e7d32 " /> :
                                <div>
                                    {notifications.map((val) => {
                                        return (
                                            val.isRead ?
                                                <Link key={val.id} to={`/notification/${val.id}`}>
                                                    <UncontrolledAlert className="alert-with-icon" color="success">
                                                        <span data-notify="icon" className="tim-icons" >
                                                            <NotificationsNoneIcon />
                                                        </span>
                                                        <span>
                                                            <TextTruncate
                                                                line={2}
                                                                element="span"
                                                                truncateText="…"
                                                                text={`${val.title}: ${val.message}`}
                                                            />
                                                        </span>

                                                    </UncontrolledAlert>
                                                </Link>
                                                :
                                                <Link key={val.id} to={`/notification/${val.id}`}>
                                                    <div className="container-message">
                                                        <span className="text-bold">
                                                            {val.title}:
                                                        </span>
                                                        <TextTruncate
                                                            line={2}
                                                            element="span"
                                                            truncateText="…"
                                                            text={` ${val.message} `}
                                                        />
                                                        <span>
                                                            <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.primary' }}>
                                                                -{formatDistance(new Date(val.date), new Date())}
                                                            </Typography>
                                                        </span>
                                                    </div>
                                                </Link>
                                        )

                                    })
                                    }
                                    <Stack spacing={2} direction="row">
                                        {page > 1 ? <Button variant="contained" color="success" onClick={previousPage}>previous</Button> : <Button variant="outlined" color="success">previous</Button>}
                                        <Button variant="contained" color="success" onClick={nextPage}>next</Button>
                                    </Stack>
                                </div>
                            }
                        </div>
                    </Container>
                </div>
            </Grid>

        </Grid >

    );
}
