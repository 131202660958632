import { useSelector } from "react-redux";
import { handleResponse } from "../_helpers/handle-response";

const { URL_SERVER } = require("../_helpers/setting");

export const notificationService = {
    getNotifications,
    getNotificationById
}

async function getNotifications(token, page) {
    const requestOptions = {
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            'device-id': token
        }
    };
    return fetch(`${URL_SERVER}/api/v1/Notification/GetNotifications?page=${page}`, requestOptions).then(handleResponse).then(notifications => {
        return JSON.parse(notifications);
    });
}

async function getNotificationById(token, id) {
    const requestOptions = {
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            'device-id': token
        }
    };
    return fetch(`${URL_SERVER}/api/v1/Notification/GetNotification?id=${id}`, requestOptions).then(handleResponse).then(notification => {
        return JSON.parse(notification);
    });
}