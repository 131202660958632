import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { Container } from "reactstrap";
import { messagesService } from "../../_services";
import { useStyles } from "../../_styles/styles";
import Button from '@mui/material/Button';

const NotificaionDetails = () => {
    let { id } = useParams();
    console.log("Init " + id);
    const classes = useStyles();
    const token = useSelector(state => state['account'].token)
    const { addToast } = useToasts();
    let history = useHistory();
    const [notification, setNotification] = useState(null);

    React.useEffect(async () => {
        var data = await getNotification();
        if (data == null)
            history.push("/notifications")

        setNotification(data);
    }, [])

    const getNotification = async () => {
        console.log("get notification " + id);
        return await messagesService.getMessagesById(id).catch(error => {
            addToast(error, {
                appearance: 'error',
                autoDismiss: true,
            });
            return null;
        })
    }

    return (
        <div className={classes.marginContent}>
            <Container className={`mt-5`}>
                <Grid spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <h3 className="text-black">{notification?.title}</h3>
                        <p className="text-black">{notification?.message}</p>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className="d-flex flex-row">
                            <div className="mt-3"><Button variant="contained" color="success" onClick={() => history.push("/notifications")}>Back</Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default NotificaionDetails;